import React, { useState } from 'react';

const BusinessPageForm = ({ updateQRData }) => {
  const initialState = {
    businessName: '',
    tagline: '',
    bannerUrl: '',
    description: '',
    website: '',
    phone: '',
    email: '',
    address: {
      street: '',
      number: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    },
    socialMedia: {
      whatsapp: '',
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: '',
      youtube: '',
      pinterest: '',
      telegram: '',
      tiktok: '',
      snapchat: '',
      reddit: '',
      tumblr: '',
      vimeo: '',
      flickr: '',
      behance: '',
      dribbble: '',
      github: '',
      medium: '',
      quora: '',
      spotify: '',
      soundcloud: '',
      twitch: '',
      yelp: '',
      tripadvisor: '',
    },
    selectedColor: '#447fb6',
    buttonColor: '#e91e63',
    features: [],
    openingHours: {
      Monday: { open: '08:00 am', close: '08:00 pm', isOpen: true },
      Tuesday: { open: '08:00 am', close: '08:00 pm', isOpen: true },
      Wednesday: { open: '08:00 am', close: '08:00 pm', isOpen: true },
      Thursday: { open: '08:00 am', close: '08:00 pm', isOpen: true },
      Friday: { open: '08:00 am', close: '08:00 pm', isOpen: true },
      Saturday: { open: '08:00 am', close: '08:00 pm', isOpen: true },
      Sunday: { open: '08:00 am', close: '08:00 pm', isOpen: true }
    },
    showSocialInputs: {}
  };

  const [state, setState] = useState(initialState);

  const socialMediaIcons = [
    { icon: '🌐', name: 'website', placeholder: 'URL', type: 'url' },
    { icon: 'f', name: 'facebook', placeholder: 'Username or URL', type: 'text' },
    { icon: '𝕏', name: 'twitter', placeholder: 'Username', type: 'text' },
    { icon: '📷', name: 'instagram', placeholder: 'Username', type: 'text' },
    { icon: 'in', name: 'linkedin', placeholder: 'URL', type: 'url' },
    { icon: '▶️', name: 'youtube', placeholder: 'Channel URL', type: 'url' },
    { icon: '📌', name: 'pinterest', placeholder: 'Username', type: 'text' },
    { icon: '✈️', name: 'telegram', placeholder: 'Username', type: 'text' },
    { icon: '🎵', name: 'tiktok', placeholder: 'Username', type: 'text' },
    { icon: '👻', name: 'snapchat', placeholder: 'Username', type: 'text' },
    { icon: '👽', name: 'reddit', placeholder: 'Username', type: 'text' },
    { icon: '🅃', name: 'tumblr', placeholder: 'Username', type: 'text' },
    { icon: 'V', name: 'vimeo', placeholder: 'Username', type: 'text' },
    { icon: '••', name: 'flickr', placeholder: 'Username', type: 'text' },
    { icon: 'Be', name: 'behance', placeholder: 'Username', type: 'text' },
    { icon: '🔵', name: 'dribbble', placeholder: 'Username', type: 'text' },
    { icon: 'GH', name: 'github', placeholder: 'Username', type: 'text' },
    { icon: 'M', name: 'medium', placeholder: 'Username', type: 'text' },
    { icon: 'Q', name: 'quora', placeholder: 'Username', type: 'text' },
    { icon: '🎵', name: 'spotify', placeholder: 'Artist URL', type: 'url' },
    { icon: '☁️', name: 'soundcloud', placeholder: 'Username', type: 'text' },
    { icon: '🎮', name: 'twitch', placeholder: 'Username', type: 'text' },
    { icon: '✳️', name: 'yelp', placeholder: 'Business URL', type: 'url' },
    { icon: '∞', name: 'tripadvisor', placeholder: 'URL', type: 'url' },
  ];

  const handleSocialIconClick = (platform) => {
    setState((prev) => ({
      ...prev,
      showSocialInputs: {
        ...prev.showSocialInputs,
        [platform]: !prev.showSocialInputs[platform],
      },
    }));
  };

  const handleNestedInputChange = (section, field, value) => {
    setState((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  const generateQRCode = () => {
    updateQRData(state);
  };

  const SocialMediaSection = () => (
    <div className="space-y-4">
      <h2 className="text-lg font-medium">Social Media Channels</h2>
      <div className="flex flex-wrap gap-4">
        {socialMediaIcons.map((platform) => (
          <div key={platform.name} className="flex flex-col items-center">
            <button
              onClick={() => handleSocialIconClick(platform.name)}
              className={`w-10 h-10 rounded-full flex items-center justify-center text-xl bg-gray-200 ${state.showSocialInputs[platform.name] ? 'bg-blue-500 text-white' : ''}`}
            >
              {platform.icon}
            </button>
            {state.showSocialInputs[platform.name] && (
              <input
                type={platform.type}
                placeholder={platform.placeholder}
                value={state.socialMedia[platform.name] || ''}
                onChange={(e) => handleNestedInputChange('socialMedia', platform.name, e.target.value)}
                className="mt-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="space-y-8">
      {/* أقسام النموذج الأخرى مثل DesignCustomizationSection، BasicInformationSection، إلخ */}
      <SocialMediaSection />
      <div className="mt-4">
        <button onClick={generateQRCode} className="bg-blue-500 text-white py-2 px-4 rounded">
          Generate QR Code
        </button>
      </div>
    </div>
  );
};

export default BusinessPageForm;
