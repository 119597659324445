import React, { useEffect, useState } from 'react';

const TextForm = ({ updateQRData }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    updateQRData({ text });
  }, [text, updateQRData]);

  return (
    <div className="space-y-4">
      <textarea 
        placeholder="Enter your text here" 
        className="w-full p-2 border border-gray-300 rounded" 
        rows="5"
        value={text}
        onChange={(e) => setText(e.target.value)}
      ></textarea>
    </div>
  );
};

export default TextForm;
