import React, { useEffect, useState } from 'react';

const TwitterForm = ({ updateQRData }) => {
  const [twitterData, setTwitterData] = useState({ option: 'profile', username: '', tweet: '' });

  useEffect(() => {
    const data = twitterData.option === 'profile'
      ? `https://twitter.com/${twitterData.username}`
      : `https://twitter.com/intent/tweet?text=${encodeURIComponent(twitterData.tweet)}`;
    updateQRData(data);
  }, [twitterData, updateQRData]);

  const handleOptionChange = (e) => {
    setTwitterData({ ...twitterData, option: e.target.value });
  };

  const handleInputChange = (e) => {
    setTwitterData({ ...twitterData, [e.target.name]: e.target.value });
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-8"> {/* تعديل المسافة هنا */}
        <label className="flex items-center">
          <input 
            type="radio" 
            name="option" 
            value="profile" 
            checked={twitterData.option === 'profile'}
            onChange={handleOptionChange} 
            className="mr-2" // مسافة بين الدائرة والنص
          />
          Link to your profile
        </label>
        <label className="flex items-center">
          <input 
            type="radio" 
            name="option" 
            value="tweet" 
            checked={twitterData.option === 'tweet'}
            onChange={handleOptionChange} 
            className="mr-2" // مسافة بين الدائرة والنص
          />
          Post a tweet
        </label>
      </div>
      {twitterData.option === 'profile' ? (
        <input 
          type="text" 
          name="username" 
          placeholder="@username" 
          className="w-full p-2 border border-gray-300 rounded"
          value={twitterData.username}
          onChange={handleInputChange}
        />
      ) : (
        <textarea 
          name="tweet" 
          placeholder="Compose your tweet" 
          className="w-full p-2 border border-gray-300 rounded" 
          rows="3"
          value={twitterData.tweet}
          onChange={handleInputChange}
        ></textarea>
      )}
    </div>
  );
};

export default TwitterForm;
