import React, { useEffect, useState } from 'react';

const BitcoinForm = ({ updateQRData }) => {
  const [bitcoinData, setBitcoinData] = useState({
    cryptocurrency: 'bitcoin',
    amount: '',
    address: '',
    message: ''
  });

  useEffect(() => {
    updateQRData(bitcoinData);
  }, [bitcoinData, updateQRData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBitcoinData({ ...bitcoinData, [name]: value });
  };

  const getPlaceholderText = () => {
    switch (bitcoinData.cryptocurrency) {
      case 'bitcoin':
        return 'Bitcoin Address';
      case 'bitcoin-cash':
        return 'Bitcoin Cash Address';
      case 'ether':
        return 'Ether Address';
      case 'litecoin':
        return 'Litecoin Address';
      case 'dash':
        return 'Dash Address';
      default:
        return 'Address';
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <label className="text-sm text-gray-700">Cryptocurrency</label>
        <div className="flex items-center space-x-2">
          <input 
            type="radio" 
            name="cryptocurrency" 
            value="bitcoin" 
            id="bitcoin" 
            checked={bitcoinData.cryptocurrency === 'bitcoin'}
            onChange={handleChange}
          />
          <label htmlFor="bitcoin" className="text-sm text-gray-700">Bitcoin</label>
        </div>
        <div className="flex items-center space-x-2">
          <input 
            type="radio" 
            name="cryptocurrency" 
            value="bitcoin-cash" 
            id="bitcoin-cash" 
            checked={bitcoinData.cryptocurrency === 'bitcoin-cash'}
            onChange={handleChange}
          />
          <label htmlFor="bitcoin-cash" className="text-sm text-gray-700">Bitcoin Cash</label>
        </div>
        <div className="flex items-center space-x-2">
          <input 
            type="radio" 
            name="cryptocurrency" 
            value="ether" 
            id="ether" 
            checked={bitcoinData.cryptocurrency === 'ether'}
            onChange={handleChange}
          />
          <label htmlFor="ether" className="text-sm text-gray-700">Ether</label>
        </div>
        <div className="flex items-center space-x-2">
          <input 
            type="radio" 
            name="cryptocurrency" 
            value="litecoin" 
            id="litecoin" 
            checked={bitcoinData.cryptocurrency === 'litecoin'}
            onChange={handleChange}
          />
          <label htmlFor="litecoin" className="text-sm text-gray-700">Litecoin</label>
        </div>
        <div className="flex items-center space-x-2">
          <input 
            type="radio" 
            name="cryptocurrency" 
            value="dash" 
            id="dash" 
            checked={bitcoinData.cryptocurrency === 'dash'}
            onChange={handleChange}
          />
          <label htmlFor="dash" className="text-sm text-gray-700">Dash</label>
        </div>
      </div>
      <input 
        type="text" 
        name="amount" 
        placeholder="Amount" 
        className="w-full p-2 border border-gray-300 rounded"
        value={bitcoinData.amount}
        onChange={handleChange}
      />
      <input 
        type="text" 
        name="address" 
        placeholder={getPlaceholderText()} 
        className="w-full p-2 border border-gray-300 rounded"
        value={bitcoinData.address}
        onChange={handleChange}
      />
      <input 
        type="text" 
        name="message" 
        placeholder="Optional" 
        className="w-full p-2 border border-gray-300 rounded"
        value={bitcoinData.message}
        onChange={handleChange}
      />
    </div>
  );
};

export default BitcoinForm;
