import React, { useEffect, useState } from 'react';

const SMSForm = ({ updateQRData }) => {
  const [smsData, setSmsData] = useState({ number: '', message: '' });

  useEffect(() => {
    updateQRData(smsData);
  }, [smsData, updateQRData]);

  const handleChange = (e) => {
    setSmsData({ ...smsData, [e.target.name]: e.target.value });
  };

  return (
    <div className="space-y-4">
      <input 
        type="tel" 
        name="number" 
        placeholder="Your phone number" 
        className="w-full p-2 border border-gray-300 rounded"
        value={smsData.number}
        onChange={handleChange}
      />
      <textarea 
        name="message" 
        placeholder="Enter your text here" 
        className="w-full p-2 border border-gray-300 rounded" 
        rows="5"
        value={smsData.message}
        onChange={handleChange}
      ></textarea>
    </div>
  );
};

export default SMSForm;
