import { Link } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';

const AppStoreForm = ({ updateQRData }) => {
  const [appName, setAppName] = useState('');
  const [developer, setDeveloper] = useState('');
  const [appLogoUrl, setAppLogoUrl] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [appStoreLink, setAppStoreLink] = useState('');
  const [googlePlayLink, setGooglePlayLink] = useState('');
  const [amazonLink, setAmazonLink] = useState('');
  const [selectedColor, setSelectedColor] = useState('#ff7464');

  const colorOptions = ['#ff7464', '#2980b9', '#8e44ad', '#27ae60', '#f39c12'];

  const memoizedUpdateQRData = useCallback((data) => {
    updateQRData(data);
  }, [updateQRData]);

  useEffect(() => {
    const data = {
      appName,
      developer,
      appLogoUrl,
      title,
      description,
      website,
      appStoreLink,
      googlePlayLink,
      amazonLink,
      color: selectedColor,
    };
    memoizedUpdateQRData(data);
  }, [appName, developer, appLogoUrl, title, description, website, appStoreLink, googlePlayLink, amazonLink, selectedColor, memoizedUpdateQRData]);

  const PreviewPage = () => (
    <div className="w-full max-w-md mx-auto mt-8 border-8 border-gray-800 rounded-3xl overflow-hidden shadow-lg">
      <div className="bg-black text-white text-xs p-2 flex justify-between items-center">
        <div>9:41</div>
        <div className="flex space-x-1">
          <div className="w-4 h-4 rounded-full bg-white"></div>
          <div className="w-4 h-4 rounded-full bg-white"></div>
          <div className="w-4 h-4 rounded-full bg-white"></div>
        </div>
      </div>
      <div className="bg-white h-[600px] overflow-y-auto">
        <div className="p-4" style={{ backgroundColor: selectedColor }}>
          <div className="flex justify-end">
            <button className="text-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
            </button>
          </div>
          <img src={appLogoUrl || '/api/placeholder/100/100'} alt="App Logo" className="w-24 h-24 mx-auto mb-2 rounded-xl" />
          <h2 className="text-xl font-bold text-white text-center">{appName || 'App Name'}</h2>
          <p className="text-sm text-white text-center">{developer || 'Developer Name'}</p>
        </div>
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-2">{title || 'Connect with App'}</h3>
          <p className="text-sm text-gray-600 mb-4">{description || 'Description: View features and details about the app...'}</p>
          <a 
            href={appStoreLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="block w-full bg-black text-white text-center py-2 rounded-md mb-2 hover:bg-gray-800 transition duration-300"
          >
            Download on the App Store
          </a>
          <a 
            href={googlePlayLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="block w-full bg-blue-500 text-white text-center py-2 rounded-md mb-2 hover:bg-blue-600 transition duration-300"
          >
            GET IT ON Google Play
          </a>
          <a 
            href={amazonLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="block w-full bg-orange-500 text-white text-center py-2 rounded-md hover:bg-orange-600 transition duration-300"
          >
            Available at amazon
          </a>
        </div>
        <div className="p-4 text-center">
          <a href={website} className="text-blue-500 hover:underline">{website}</a>
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium mb-2">Design & Customization</h2>
        <p className="text-sm text-gray-600 mb-2">Personalize your page by selecting a color.</p>
        <div className="flex space-x-2">
          {colorOptions.map((color) => (
            <div
              key={color}
              className={`w-8 h-8 rounded-full cursor-pointer ${color === selectedColor ? 'ring-2 ring-offset-2 ring-blue-500' : ''}`}
              style={{ backgroundColor: color }}
              onClick={() => setSelectedColor(color)}
            />
          ))}
        </div>
      </div>

      <div>
        <h2 className="text-lg font-medium mb-2">App Information</h2>
        <p className="text-sm text-gray-600 mb-4">Provide your app information and add some context to your page.</p>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="App Name"
            value={appName}
            onChange={(e) => setAppName(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          />
          <input
            type="text"
            placeholder="Developer"
            value={developer}
            onChange={(e) => setDeveloper(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          />
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">App Logo URL</label>
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Enter logo URL"
                value={appLogoUrl}
                onChange={(e) => setAppLogoUrl(e.target.value)}
                className="flex-grow px-3 py-2 border rounded-md mr-2"
              />
              <button className="bg-blue-500 text-white px-4 py-2 rounded">
                <Link className="inline-block mr-2" size={16} />
                Add URL
              </button>
            </div>
          </div>
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          />
          <textarea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            rows={3}
          />
          <input
            type="url"
            placeholder="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
      </div>

      <div>
        <h2 className="text-lg font-medium mb-2">App Store Links</h2>
        <p className="text-sm text-gray-600 mb-4">Provide links to your app on various app stores.</p>
        <div className="space-y-4">
          <input
            type="url"
            placeholder="App Store Link"
            value={appStoreLink}
            onChange={(e) => setAppStoreLink(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          />
          <input
            type="url"
            placeholder="Google Play Link"
            value={googlePlayLink}
            onChange={(e) => setGooglePlayLink(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          />
          <input
            type="url"
            placeholder="Amazon Appstore Link"
            value={amazonLink}
            onChange={(e) => setAmazonLink(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
      </div>

      <div>
        <h2 className="text-lg font-medium mb-2">Preview</h2>
        <PreviewPage />
      </div>
    </div>
  );
};

export default AppStoreForm;