import { saveAs } from 'file-saver';
import * as htmlToImage from 'html-to-image';
import { Barcode, Download } from 'lucide-react';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import AppStoreForm from '../forms/AppStoreForm';
import BitcoinForm from '../forms/BitcoinForm';
import BusinessPageForm from '../forms/BusinessPageForm';
import EmailForm from '../forms/EmailForm';
import FacebookForm from '../forms/FacebookForm';
import LinktreeForm from '../forms/LinktreeForm';
import PDFForm from '../forms/PDFForm';
import SMSForm from '../forms/SMSForm';
import TextForm from '../forms/TextForm';
import TwitterForm from '../forms/TwitterForm';
import URLForm from '../forms/URLForm';
import VCardForm from '../forms/VCardForm';
import WhatsAppForm from '../forms/WhatsAppForm';
import WiFiForm from '../forms/WiFiForm';
import { getIconForType } from '../utils/getIconForType';
import AppStoreQRResult from './AppStoreQRResult';
import BusinessPageQRResult from './BusinessPageQRResult';
import LinktreeQRResult from './LinktreeQRResult';
import PDFQRResult from './PDFQRResult';

const CustomQRCode = ({ value, size, fgColor, shape, logoOption, logoImage }) => {
  const errorCorrectionLevel = logoOption !== 'none' ? 'H' : 'M';

  let imageSettings = null;

  if (logoOption === 'userLogo' && logoImage) {
    imageSettings = {
      src: logoImage,
      x: undefined,
      y: undefined,
      height: size * 0.2,
      width: size * 0.2,
      excavate: true,
    };
  }

  const qrProps = {
    value,
    size,
    fgColor,
    level: errorCorrectionLevel,
    includeMargin: false,
    imageSettings,
    qrStyle: shape === 'dots' ? 'dots' : 'squares',
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <QRCodeSVG {...qrProps} />
      {logoOption === 'scanMe' && (
        <div
          style={{
            position: 'absolute',
            bottom: '-30px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#000000',
            color: '#ffffff',
            padding: '5px 10px',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: size * 0.06,
            textAlign: 'center',
          }}
        >
          SCAN ME
        </div>
      )}
    </div>
  );
};

const DownloadModal = ({ isOpen, onClose, onDownload }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Choose Download Format</h2>
        <div className="space-y-2">
          <button
            onClick={() => onDownload('png')}
            className="w-full bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
          >
            Download as PNG
          </button>
          <button
            onClick={() => onDownload('svg')}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            Download as SVG
          </button>
        </div>
        <button
          onClick={onClose}
          className="mt-4 w-full bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const frames = [
  { id: 1, name: "No Frame", style: {} },
  {
    id: 2,
    name: "Solid Frame",
    style: {
      position: 'relative',
      display: 'inline-block',
      border: '5px solid black',
      padding: '10px',
      backgroundColor: 'white',
      textAlign: 'center',
    },
    hasScanMeText: true,
  },
];

const Dropdown = ({ title, badge, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-2">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <h3 className="font-semibold text-sm text-gray-700">{title}</h3>
          {badge && <span className="ml-2 bg-blue-500 text-white text-xs px-1 rounded">{badge}</span>}
        </div>
        <span>{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div className="mt-2">
          {children}
        </div>
      )}
    </div>
  );
};

const ShapeColorSelector = ({ selectedShape, setSelectedShape, qrColor, setQrColor }) => {
  const shapes = [
    { id: 'squares', label: '■', description: 'Squares' },
    { id: 'dots', label: '●', description: 'Dots' },
  ];

  return (
    <div className="flex flex-wrap items-center gap-2">
      {shapes.map((shape) => (
        <button
          key={shape.id}
          onClick={() => setSelectedShape(shape.id)}
          className={`w-10 h-10 flex items-center justify-center border ${
            selectedShape === shape.id ? 'border-blue-500' : 'border-gray-300'
          }`}
        >
          <span>{shape.label}</span>
        </button>
      ))}
      <input
        type="color"
        value={qrColor}
        onChange={(e) => setQrColor(e.target.value)}
        className="w-10 h-10 border border-gray-300 rounded-full overflow-hidden"
      />
    </div>
  );
};

const LogoSelector = ({ logoOption, setLogoOption, setLogoImage }) => {
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        alert('Please upload an image file.');
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogoImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="space-y-2">
      <label className="flex items-center">
        <input
          type="radio"
          name="logoOption"
          value="none"
          checked={logoOption === 'none'}
          onChange={() => setLogoOption('none')}
          className="mr-2"
        />
        No Logo
      </label>
      <label className="flex items-center">
        <input
          type="radio"
          name="logoOption"
          value="scanMe"
          checked={logoOption === 'scanMe'}
          onChange={() => setLogoOption('scanMe')}
          className="mr-2"
        />
        'SCAN ME' Text
      </label>
      <label className="flex items-center">
        <input
          type="radio"
          name="logoOption"
          value="userLogo"
          checked={logoOption === 'userLogo'}
          onChange={() => setLogoOption('userLogo')}
          className="mr-2"
        />
        Upload Logo
      </label>
      {logoOption === 'userLogo' && (
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className="mt-2"
        />
      )}
    </div>
  );
};

const QRCodeGenerator = () => {
  const [activeType, setActiveType] = useState('URL');
  const [scanTracking, setScanTracking] = useState(false);

  const defaultURL = 'https://all-qr-code-generator.com';

  const [qrData, setQRData] = useState(defaultURL);

  const [selectedFrame, setSelectedFrame] = useState(frames[0]);
  const [showQRResult, setShowQRResult] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [selectedShape, setSelectedShape] = useState('squares');
  const [qrColor, setQrColor] = useState('#000000');
  const qrCodeRef = useRef(null);
  const [logoOption, setLogoOption] = useState('none');
  const [logoImage, setLogoImage] = useState(null);
  const [linktreeData, setLinktreeData] = useState(null);
  const [uniqueLink, setUniqueLink] = useState('');

// Обновляем useEffect
useEffect(() => {
  updateQRData({});
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [activeType]);
  
// أضف هذا التعليق فوق تعريف updateQRData مباشرة
// eslint-disable-next-line react-hooks/exhaustive-deps
const updateQRData = (data) => {
  switch (activeType) {
      case 'URL':
        setQRData(data.url || defaultURL);
        break;
      case 'VCARD':
        if (Object.keys(data).length === 0) {
          setQRData(defaultURL);
        } else {
          setQRData(`BEGIN:VCARD\nVERSION:3.0\nN:${data.lastName};${data.firstName}\nFN:${data.firstName} ${data.lastName}\nTEL;CELL:${data.mobile}\nTEL;WORK:${data.phone}\nTEL;FAX:${data.fax}\nEMAIL:${data.email}\nORG:${data.company}\nTITLE:${data.job}\nADR;TYPE=WORK:${data.street};${data.city};${data.state};${data.zip};${data.country}\nURL:${data.website}\nEND:VCARD`);
        }
        break;
      case 'TEXT':
        setQRData(data.text || defaultURL);
        break;
      case 'SMS':
        if (Object.keys(data).length === 0) {
          setQRData(defaultURL);
        } else {
          setQRData(`SMSTO:${data.number}:${data.message}`);
        }
        break;
      case 'WIFI':
        if (Object.keys(data).length === 0) {
          setQRData(defaultURL);
        } else {
          setQRData(`WIFI:T:${data.encryption};S:${data.ssid};P:${data.password};H:${data.hidden ? 'true' : 'false'};;`);
        }
        break;
      case 'BITCOIN':
        if (Object.keys(data).length === 0) {
          setQRData(defaultURL);
        } else {
          setQRData(`${data.cryptocurrency}:${data.address}?amount=${data.amount}&message=${encodeURIComponent(data.message)}`);
        }
        break;
      case 'E-MAIL':
        if (Object.keys(data).length === 0) {
          setQRData(defaultURL);
        } else {
          setQRData(`mailto:${data.email}?subject=${encodeURIComponent(data.subject)}&body=${encodeURIComponent(data.message)}`);
        }
        break;
      case 'WHATSAPP':
        if (Object.keys(data).length === 0) {
          setQRData(defaultURL);
        } else {
          setQRData(`https://wa.me/${data.phoneNumber}?text=${encodeURIComponent(data.message)}`);
        }
        break;
        case 'LINKTREE':
          if (Object.keys(data).length === 0) {
            setQRData(defaultURL);
          } else {
            setLinktreeData(data);
            const fakeUniqueId = Math.random().toString(36).substring(2, 15);
            const newUniqueLink = `https://all-qr-code-generator.com/linktree/${fakeUniqueId}`;
            setUniqueLink(newUniqueLink);
            setQRData(newUniqueLink);
          }
          break;
        default:
          setQRData(defaultURL);
          break;
      }
    };

  const handleFrameSelection = (frame) => {
    setSelectedFrame(frame);
  };

  const handleDownload = async (format) => {
    if (!qrCodeRef.current) return;

    try {
      let dataUrl;
      if (format === 'png') {
        dataUrl = await htmlToImage.toPng(qrCodeRef.current, { backgroundColor: '#fff' });
      } else if (format === 'svg') {
        const svgData = new XMLSerializer().serializeToString(qrCodeRef.current.querySelector('svg'));
        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        dataUrl = URL.createObjectURL(svgBlob);
      }

      const fileName = `qr-code.${format}`;
      saveAs(dataUrl, fileName);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
    setShowDownloadModal(false);
  };

  if (showQRResult) {
    switch (activeType) {
      case 'PDF':
        return <PDFQRResult data={qrData} />;
      case 'APP STORES':
        return <AppStoreQRResult data={qrData} />;
      case 'BUSINESS PAGE':
        return <BusinessPageQRResult data={qrData} />;
      case 'LINKTREE':
        return <LinktreeQRResult data={linktreeData} uniqueLink={uniqueLink} />;
      default:
        setShowQRResult(false);
        return null;
    }
  }

  const renderInputForm = () => {
    switch (activeType) {
      case 'URL':
        return <URLForm updateQRData={updateQRData} />;
      case 'VCARD':
        return <VCardForm updateQRData={updateQRData} />;
      case 'TEXT':
        return <TextForm updateQRData={updateQRData} />;
      case 'SMS':
        return <SMSForm updateQRData={updateQRData} />;
      case 'WIFI':
        return <WiFiForm updateQRData={updateQRData} />;
      case 'BITCOIN':
        return <BitcoinForm updateQRData={updateQRData} />;
        case 'E-MAIL':
          return <EmailForm updateQRData={updateQRData} />;
        case 'APP STORES':
          return <AppStoreForm updateQRData={updateQRData} />;
        case 'TWITTER':
          return <TwitterForm updateQRData={updateQRData} />;
        case 'FACEBOOK':
          return <FacebookForm updateQRData={updateQRData} />;
        case 'PDF':
          return <PDFForm updateQRData={updateQRData} />;
        case 'BUSINESS PAGE':
          return <BusinessPageForm updateQRData={updateQRData} />;
        case 'WHATSAPP':
          return <WhatsAppForm updateQRData={updateQRData} />;
          case 'LINKTREE':
            return <LinktreeForm updateQRData={updateQRData} />;
          default:
            return null;
        }
      };
  
    return (
      <div className="flex flex-col lg:flex-row bg-white rounded-lg shadow-md p-4 lg:p-8">
        <div className="flex-grow lg:mr-8 mb-4 lg:mb-0">
          <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-7 gap-2 mb-8">
          {['URL', 'VCARD', 'TEXT', 'E-MAIL', 'SMS', 'WIFI', 'BITCOIN', 'TWITTER', 'FACEBOOK', 'PDF', 'WHATSAPP', 'APP STORES', 'BUSINESS PAGE', 'LINKTREE'].map((type) => (
              <button
                key={type}
                className={`p-2 rounded ${activeType === type ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveType(type)}
              >
                {getIconForType(type)}
                <span className="text-xs">{type}</span>
              </button>
            ))}
          </div>
  
          <div className="mb-8">
            <h2 className="text-2xl text-gray-700 font-light mb-1">Enter {activeType.toLowerCase()} details</h2>
            <p className="text-sm text-gray-400">(QR code will be generated automatically)</p>
          </div>
  
          {renderInputForm()}
  
          <div className="flex items-center justify-between mt-20">
            <button className="flex items-center text-blue-500 text-sm">
              <Download size={16} className="mr-2 rotate-180" />
              Upload any file (.jpg, .pdf, .mp3, .docx, .pptx)
            </button>
  
            <div className="flex items-center">
              <span className="mr-2 text-gray-600 text-sm">Scan Tracking</span>
              <div
                className={`w-10 h-6 ${scanTracking ? 'bg-blue-500' : 'bg-gray-300'} rounded-full p-1 cursor-pointer`}
                onClick={() => setScanTracking(!scanTracking)}
              >
                <div className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${scanTracking ? 'translate-x-4' : ''}`}></div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="w-full lg:w-64">
          <div className="border rounded-lg p-4 relative">
            <div
              ref={qrCodeRef}
              className="relative w-full bg-gray-100 mb-4 flex items-center justify-center"
              style={{ position: 'relative' }}
            >
              {qrData ? (
                <>
                  <div style={{ ...selectedFrame.style }}>
                    <CustomQRCode
                      value={qrData}
                      size={200}
                      fgColor={qrColor}
                      shape={selectedShape}
                      logoOption={logoOption}
                      logoImage={logoImage}
                    />
                    {selectedFrame.hasScanMeText && (
                      <div
                        style={{
                          backgroundColor: 'black',
                          color: 'white',
                          padding: '10px',
                          marginTop: '10px',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          width: '100%',
                        }}
                      >
                        SCAN ME
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="w-3/4 h-3/4 bg-white"></div>
              )}
            </div>
            <Dropdown title="FRAME" badge="NEW!">
              <div className="grid grid-cols-2 gap-2">
                {frames.map((frame) => (
                  <button
                    key={frame.id}
                    onClick={() => handleFrameSelection(frame)}
                    className="p-2 border rounded hover:bg-gray-100 text-sm"
                  >
                    {frame.name}
                  </button>
                ))}
              </div>
            </Dropdown>
            <Dropdown title="SHAPE & COLOR">
              <ShapeColorSelector
                selectedShape={selectedShape}
                setSelectedShape={setSelectedShape}
                qrColor={qrColor}
                setQrColor={setQrColor}
              />
            </Dropdown>
            <Dropdown title="LOGO">
              <LogoSelector
                logoOption={logoOption}
                setLogoOption={setLogoOption}
                setLogoImage={setLogoImage}
              />
            </Dropdown>
            <div className="mt-4 space-y-2">
              <button
                onClick={() => setShowDownloadModal(true)}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded text-sm font-semibold flex items-center justify-center transition duration-300"
              >
                <Download size={16} className="mr-2 rotate-180" />
                Download QR Code
              </button>
              <button
                className="w-full border border-gray-300 text-gray-700 hover:bg-gray-50 px-4 py-2 rounded text-sm font-semibold flex items-center justify-center transition duration-300"
                onClick={() => console.log('Barcode functionality to be implemented later')}
              >
                <Barcode size={16} className="mr-2 rotate-180" />
                Download Barcode (Inactive)
              </button>
            </div>
          </div>
          <button
            onClick={() => setShowQRResult(true)}
            className="mt-4 w-full bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded text-sm font-semibold flex items-center justify-center transition duration-300"
          >
            Simulate QR Code Scan
          </button>
        </div>
        <DownloadModal
          isOpen={showDownloadModal}
          onClose={() => setShowDownloadModal(false)}
          onDownload={handleDownload}
        />
      </div>
    );
  };
  
  export default QRCodeGenerator;