import React from 'react';

const AppStoreQRResult = ({ data }) => {
  const {
    appName,
    developer,
    appLogoUrl,
    title,
    description,
    website,
    appStoreLink,
    googlePlayLink,
    amazonLink,
    color,
  } = JSON.parse(data);

  return (
    <div className="w-full max-w-md mx-auto mt-8 border-8 border-gray-800 rounded-3xl overflow-hidden shadow-lg">
      <div className="bg-black text-white text-xs p-2 flex justify-between items-center">
        <div>9:41</div>
        <div className="flex space-x-1">
          <div className="w-4 h-4 rounded-full bg-white"></div>
          <div className="w-4 h-4 rounded-full bg-white"></div>
          <div className="w-4 h-4 rounded-full bg-white"></div>
        </div>
      </div>
      <div className="bg-white h-[600px] overflow-y-auto">
        <div className="p-4" style={{ backgroundColor: color }}>
          <div className="flex justify-end">
            <button className="text-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
            </button>
          </div>
          <img src={appLogoUrl || '/api/placeholder/100/100'} alt="App Logo" className="w-24 h-24 mx-auto mb-2 rounded-xl" />
          <h2 className="text-xl font-bold text-white text-center">{appName}</h2>
          <p className="text-sm text-white text-center">{developer}</p>
        </div>
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-2">{title}</h3>
          <p className="text-sm text-gray-600 mb-4">{description}</p>
          <a 
            href={appStoreLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="block w-full bg-black text-white text-center py-2 rounded-md mb-2 hover:bg-gray-800 transition duration-300"
          >
            Download on the App Store
          </a>
          <a 
            href={googlePlayLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="block w-full bg-blue-500 text-white text-center py-2 rounded-md mb-2 hover:bg-blue-600 transition duration-300"
          >
            GET IT ON Google Play
          </a>
          <a 
            href={amazonLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="block w-full bg-orange-500 text-white text-center py-2 rounded-md hover:bg-orange-600 transition duration-300"
          >
            Available at amazon
          </a>
        </div>
        <div className="p-4 text-center">
          <a href={website} className="text-blue-500 hover:underline">{website}</a>
        </div>
      </div>
    </div>
  );
};

export default AppStoreQRResult;