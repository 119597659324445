import React, { useEffect, useState } from 'react';

const EmailForm = ({ updateQRData }) => {
  const [email, setEmail] = useState({ email: '', subject: '', message: '' });

  useEffect(() => {
    updateQRData(email);
  }, [email, updateQRData]);

  const handleChange = (e) => {
    setEmail({ ...email, [e.target.name]: e.target.value });
  };

  return (
    <div className="space-y-4">
      <input type="email" name="email" placeholder="Email Address" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
      <input type="text" name="subject" placeholder="Subject" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
      <textarea name="message" placeholder="Message" className="w-full p-2 border border-gray-300 rounded" rows="4" onChange={handleChange}></textarea>
    </div>
  );
};

export default EmailForm;
