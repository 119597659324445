import { Link as LinkIcon } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';

const PDFForm = ({ updateQRData }) => {
  const [externalPdfLink, setExternalPdfLink] = useState('');
  const [selectedColor, setSelectedColor] = useState('#da5167');
  const [company, setCompany] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');

  const colorOptions = ['#da5167', '#2980b9', '#8e44ad', '#27ae60', '#f39c12'];

  const memoizedUpdateQRData = useCallback((data) => {
    updateQRData(data);
  }, [updateQRData]);

  useEffect(() => {
    const data = {
      pdfUrl: externalPdfLink,
      company,
      title,
      description,
      website,
      color: selectedColor,
    };
    memoizedUpdateQRData(data);
  }, [externalPdfLink, company, title, description, website, selectedColor, memoizedUpdateQRData]);

  const MobilePreview = () => (
    <div className="w-64 mx-auto mt-8 border-8 border-gray-800 rounded-3xl overflow-hidden shadow-lg">
      <div className="bg-black text-white text-xs p-2">12:00</div>
      <div className="bg-white h-96 overflow-y-auto">
        <div className="p-4" style={{ backgroundColor: selectedColor }}>
          <h2 className="text-xl font-bold text-white">{company || 'Company Name'}</h2>
          <h3 className="text-lg text-white">{title || 'PDF Title'}</h3>
          <p className="text-sm text-white mt-2">{description || 'PDF Description'}</p>
        </div>
        <div className="p-4">
          <img 
            src="/path/to/pdf/preview/image.jpg" 
            alt="PDF Preview" 
            className="w-full h-auto rounded-lg shadow-md"
          />
          <a 
            href={externalPdfLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="block w-full bg-blue-500 text-white text-center py-2 rounded-md mt-4 hover:bg-blue-600 transition duration-300"
          >
            View PDF
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium mb-2">PDF Link</h2>
        <div className="flex items-center space-x-4 mb-2">
          <input
            type="text"
            placeholder="Enter external PDF link"
            value={externalPdfLink}
            onChange={(e) => setExternalPdfLink(e.target.value)}
            className="flex-grow px-3 py-2 border rounded-md"
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            <LinkIcon className="inline-block mr-2" size={16} />
            Use Link
          </button>
        </div>
      </div>

      <div>
        <h2 className="text-lg font-medium mb-2">Design & Customization</h2>
        <p className="text-sm text-gray-600 mb-2">Personalize your page by selecting some colors.</p>
        <div className="flex space-x-2">
          {colorOptions.map((color) => (
            <div
              key={color}
              className={`w-8 h-8 rounded-full cursor-pointer ${color === selectedColor ? 'ring-2 ring-offset-2 ring-blue-500' : ''}`}
              style={{ backgroundColor: color }}
              onClick={() => setSelectedColor(color)}
            />
          ))}
        </div>
      </div>

      <div>
        <h2 className="text-lg font-medium mb-2">Basic Information</h2>
        <p className="text-sm text-gray-600 mb-4">Provide your business info and add some context to your PDF.</p>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          />
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          />
          <textarea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            rows={3}
          />
          <input
            type="url"
            placeholder="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
      </div>

      <div>
        <h2 className="text-lg font-medium mb-2">Preview</h2>
        <MobilePreview />
      </div>
    </div>
  );
};

export default PDFForm;