import { EyeOff, Upload } from 'lucide-react';
import React, { useEffect, useState } from 'react';

const FacebookForm = ({ updateQRData }) => {
  const [facebookData, setFacebookData] = useState({
    pageUrl: '',
    hideLikes: false,
    name: '',
    title: 'Click on the Like Button to follow us on Facebook.',
    website: '',
    logo: null,
  });

  useEffect(() => {
    // تأكد من أن البيانات يتم تمريرها بالشكل الصحيح
    updateQRData({
      pageUrl: facebookData.pageUrl,
      hideLikes: facebookData.hideLikes,
      name: facebookData.name,
      title: facebookData.title,
      website: facebookData.website,
      logo: facebookData.logo,
    });
  }, [facebookData, updateQRData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFacebookData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    setFacebookData((prevData) => ({
      ...prevData,
      logo: e.target.files[0],
    }));
  };

  return (
    <div className="space-y-8">
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Facebook URL*</label>
        <input 
          type="url" 
          name="pageUrl" 
          placeholder="https://www.facebook.com/YourPage" 
          className="w-full p-2 border border-gray-300 rounded mb-2"
          value={facebookData.pageUrl}
          onChange={handleChange}
        />
        <label className="flex items-center mb-2">
          <input 
            type="checkbox" 
            name="hideLikes" 
            className="mr-2"
            checked={facebookData.hideLikes}
            onChange={handleChange}
          />
          <EyeOff size={16} className="mr-2" /> Hide the number of Likes on the Like button
        </label>
        <input 
          type="text" 
          name="name" 
          placeholder="Company, brand or artist who runs the Facebook Page" 
          className="w-full p-2 border border-gray-300 rounded mb-2"
          value={facebookData.name}
          onChange={handleChange}
        />
        <input 
          type="text" 
          name="title" 
          placeholder="Click on the Like Button to follow us on Facebook." 
          className="w-full p-2 border border-gray-300 rounded mb-2"
          value={facebookData.title}
          onChange={handleChange}
        />
        <input 
          type="url" 
          name="website" 
          placeholder="https://www.yourwebsite.com" 
          className="w-full p-2 border border-gray-300 rounded"
          value={facebookData.website}
          onChange={handleChange}
        />
      </div>

      {/* شاشة الترحيب */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Image</label>
        <button className="flex items-center text-blue-500">
          <Upload size={20} className="mr-2" />
          Change
        </button>
        <input 
          type="file" 
          name="logo" 
          className="mt-2"
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default FacebookForm;
