import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';

const socialPlatforms = [
  { name: 'Instagram', icon: FaInstagram },
  { name: 'YouTube', icon: FaYoutube },
  { name: 'WhatsApp', icon: FaWhatsapp },
  { name: 'Twitter', icon: FaTwitter },
  { name: 'Facebook', icon: FaFacebook },
  { name: 'LinkedIn', icon: FaLinkedin },
  { name: 'TikTok', icon: FaTiktok },
];

const LinktreeForm = ({ updateQRData }) => {
  const [username, setUsername] = useState('');
  const [links, setLinks] = useState([]);
  const [bgColor, setBgColor] = useState('#FFC0CB'); // Pink background

  const addLink = (platform) => {
    setLinks([...links, { id: Date.now(), platform, url: '' }]);
  };

  const updateLink = (id, url) => {
    setLinks(links.map(link => link.id === id ? { ...link, url } : link));
  };

  const removeLink = (id) => {
    setLinks(links.filter(link => link.id !== id));
  };

  const handleSubmit = () => {
    updateQRData({ type: 'LINKTREE', username, links, bgColor });
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-2/3 pr-4">
        <input
          type="text"
          placeholder="Your username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
        />
        <div className="mb-4">
          <h3 className="mb-2">Add your links:</h3>
          <div className="flex flex-wrap">
            {socialPlatforms.map(platform => (
              <button
                key={platform.name}
                onClick={() => addLink(platform.name)}
                className="m-1 p-2 bg-gray-200 rounded"
              >
                <platform.icon />
              </button>
            ))}
          </div>
        </div>
        {links.map(link => (
          <div key={link.id} className="flex items-center mb-2">
            <div className="mr-2">
              {socialPlatforms.find(p => p.name === link.platform).icon()}
            </div>
            <input
              type="text"
              placeholder={`Your ${link.platform} URL`}
              value={link.url}
              onChange={(e) => updateLink(link.id, e.target.value)}
              className="flex-grow p-2 border rounded"
            />
            <button onClick={() => removeLink(link.id)} className="ml-2 text-red-500">Remove</button>
          </div>
        ))}
        <div className="mt-4">
          <label className="block mb-2">Background Color:</label>
          <input
            type="color"
            value={bgColor}
            onChange={(e) => setBgColor(e.target.value)}
            className="p-1 border rounded"
          />
        </div>
        <button onClick={handleSubmit} className="mt-4 p-2 bg-blue-500 text-white rounded">
          Create My Linktree
        </button>
      </div>
      <div className="w-full md:w-1/3 mt-4 md:mt-0">
        <div className="p-4 rounded-lg" style={{ backgroundColor: bgColor }}>
          <div className="bg-white rounded-full w-20 h-20 mx-auto mb-4 flex items-center justify-center text-2xl font-bold">
            {username.charAt(0).toUpperCase()}
          </div>
          <div className="text-center mb-4">@{username}</div>
          {links.map(link => (
            <div key={link.id} className="bg-white p-2 mb-2 rounded text-center">
              {link.platform}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LinktreeForm;