import { AppWindow, Barcode, Bitcoin, CreditCard, Facebook, FileIcon, FileText, Image, Link, Mail, MessageSquare, Music, Twitter, Wifi } from 'lucide-react';
import { FaLink, FaWhatsapp } from 'react-icons/fa'; // استيراد FaLink لـ Linktree

export const getIconForType = (type) => {
  const iconMap = {
    'URL': <Link size={16} />,
    'VCARD': <CreditCard size={16} />,
    'TEXT': <FileText size={16} />,
    'E-MAIL': <Mail size={16} />,
    'SMS': <MessageSquare size={16} />,
    'WIFI': <Wifi size={16} />,
    'BITCOIN': <Bitcoin size={16} />,
    'TWITTER': <Twitter size={16} />,
    'FACEBOOK': <Facebook size={16} />,
    'PDF': <FileIcon size={16} />,
    'WHATSAPP': <FaWhatsapp size={16} />,
    'MP3': <Music size={16} />,
    'APP STORES': <AppWindow size={16} />,
    'BUSINESS PAGE': <AppWindow size={16} />, // يمكنك تغيير هذا إلى أيقونة أكثر ملاءمة إذا وجدت
    'IMAGES': <Image size={16} />,
    '2D BARCODES': <Barcode size={16} />,
    'LINKTREE': <FaLink size={16} />, // إضافة أيقونة Linktree
  };
  
  return iconMap[type] || <Barcode size={16} />; // استخدام Barcode كأيقونة افتراضية
};