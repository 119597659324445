import React, { useEffect, useState } from 'react';

const WiFiForm = ({ updateQRData }) => {
  const [wifiData, setWifiData] = useState({
    ssid: '', 
    password: '', 
    encryption: 'WPA', 
    hidden: false
  });

  useEffect(() => {
    updateQRData(wifiData);
  }, [wifiData, updateQRData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setWifiData({
      ...wifiData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  return (
    <div className="space-y-4">
      <input 
        type="text" 
        name="ssid" 
        placeholder="SSID" 
        className="w-full p-2 border border-gray-300 rounded"
        value={wifiData.ssid}
        onChange={handleChange}
      />
      <input 
        type="password" 
        name="password" 
        placeholder="Password" 
        className="w-full p-2 border border-gray-300 rounded"
        value={wifiData.password}
        onChange={handleChange}
      />
      <div className="flex items-center">
        <input 
          type="checkbox" 
          name="hidden" 
          id="hidden" 
          className="mr-2"
          checked={wifiData.hidden}
          onChange={handleChange}
        />
        <label htmlFor="hidden" className="text-sm text-gray-700">Hidden</label>
      </div>
      <div className="flex items-center space-x-4">
        <label className="text-sm text-gray-700">Encryption</label>
        <div className="flex items-center space-x-2">
          <input 
            type="radio" 
            name="encryption" 
            value="None" 
            id="none" 
            checked={wifiData.encryption === 'None'}
            onChange={handleChange}
          />
          <label htmlFor="none" className="text-sm text-gray-700">None</label>
        </div>
        <div className="flex items-center space-x-2">
          <input 
            type="radio" 
            name="encryption" 
            value="WPA" 
            id="wpa" 
            checked={wifiData.encryption === 'WPA'}
            onChange={handleChange}
          />
          <label htmlFor="wpa" className="text-sm text-gray-700">WPA/WPA2</label>
        </div>
        <div className="flex items-center space-x-2">
          <input 
            type="radio" 
            name="encryption" 
            value="WEP" 
            id="wep" 
            checked={wifiData.encryption === 'WEP'}
            onChange={handleChange}
          />
          <label htmlFor="wep" className="text-sm text-gray-700">WEP</label>
        </div>
      </div>
    </div>
  );
};

export default WiFiForm;
