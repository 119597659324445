import React, { useEffect, useState } from 'react';

const URLForm = ({ updateQRData }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    updateQRData({ url });
  }, [url, updateQRData]);

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  return (
    <div className="space-y-4">
      <input 
        type="url" 
        placeholder="https://all-qr-code-generator.com"
        className="w-full p-2 border border-gray-300 rounded"
        value={url}
        onChange={handleUrlChange}
      />
    </div>
  );
};

export default URLForm;