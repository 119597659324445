// src/components/BusinessPageQRResult.js

import React from 'react';

const BusinessPageQRResult = ({ data }) => {
  const { businessPageUrl } = JSON.parse(data);

  return (
    <div className="w-full max-w-md mx-auto mt-8 border-8 border-gray-800 rounded-3xl overflow-hidden shadow-lg">
      <div className="bg-white h-[600px] overflow-y-auto p-4">
        <h2 className="text-2xl font-bold text-center mb-4">Business Page QR Result</h2>
        <div className="text-center">
          <p className="mb-4">Scan this QR code to visit the business page:</p>
          <img src={`/api/qrcode?text=${encodeURIComponent(businessPageUrl)}`} alt="Business Page QR Code" />
          <a href={businessPageUrl} target="_blank" rel="noopener noreferrer" className="block text-blue-500 hover:underline mt-4">
            {businessPageUrl}
          </a>
        </div>
      </div>
    </div>
  );
};

export default BusinessPageQRResult;
