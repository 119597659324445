import React, { useEffect, useState } from 'react';

const VCardForm = ({ updateQRData }) => {
  const [vcard, setVcard] = useState({
    firstName: '', 
    lastName: '', 
    mobile: '', 
    phone: '', 
    fax: '', 
    email: '', 
    company: '', 
    job: '', 
    street: '', 
    city: '', 
    state: '', 
    zip: '', 
    country: '', 
    website: ''
  });

  useEffect(() => {
    updateQRData(vcard);
  }, [vcard, updateQRData]);

  const handleChange = (e) => {
    setVcard({ ...vcard, [e.target.name]: e.target.value });
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <input type="text" name="firstName" placeholder="First Name" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
        <input type="text" name="lastName" placeholder="Last Name" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <input type="tel" name="mobile" placeholder="Mobile" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
        <input type="tel" name="phone" placeholder="Phone" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
      </div>
      <input type="tel" name="fax" placeholder="Fax" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
      <input type="email" name="email" placeholder="Email" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
      <div className="grid grid-cols-2 gap-4">
        <input type="text" name="company" placeholder="Company" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
        <input type="text" name="job" placeholder="Your Job" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
      </div>
      <input type="text" name="street" placeholder="Street" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
      <div className="grid grid-cols-3 gap-4">
        <input type="text" name="city" placeholder="City" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
        <input type="text" name="state" placeholder="State" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
        <input type="text" name="zip" placeholder="ZIP" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
      </div>
      <input type="text" name="country" placeholder="Country" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
      <input type="url" name="website" placeholder="Website" className="w-full p-2 border border-gray-300 rounded" onChange={handleChange} />
    </div>
  );
};

export default VCardForm;
